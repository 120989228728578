import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';
import ContactForm from '../components/Form/ContactForm';
import SocialShare from '../components/Shop/SocialShare';

const ContactPage = (props) => {
  const {
    data: { bgContact },
  } = props;
  


  return (
    <BodyClassName className="body-light page-contact">
      <DefaultLayoutComponent title="Contact">
        <div className="site-main">
          <BackgroundImage
            className="page-banner bgImage"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext>
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Contact</h3>
              </div>
              <div className="section bg-light pd-t-0 contact">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="breadcrumb pd-x-sm"></div>
                  <div className="contact-block columns d-flex pd-x-sm">
                    <div className="cols contact-form pd-x-0">
                      <h4>Drop us a line</h4>
                      <ContactForm />
                    </div>
                    <div className="cols contact-map pd-x-0">
                      <div className="map-contact">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12578657.36814097!2d-121.06106059999999!3d39.21906079999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809b703f5e27f1a9%3A0x63ba323ef37bb379!2sGrass%20Valley%2C%20CA!5e0!3m2!1sen!2sus!4v1710796012345!5m2!1sen!2sus"
                          width="600"
                          height="450"
                          frameBorder="0"
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"></iframe>
                      </div>
                      <SocialShare title="Contact Us" />
                      <div className="contact-social">
                        <div className="contact-info" style={{ marginTop: '1rem' }}>
                          <p>
                            {' '}
                            <strong> Address: </strong> Lineage Journey, PO Box 405, Grass Valley, CA 95945, USA
                          </p>

                          <p>
                            <strong>Phone Number:</strong> <a href="tel:+15306876960">+1 (530) 687-6960</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default ContactPage;
