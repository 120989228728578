import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function ContactForm() {
  const [contactInfo, setContactInfo] = useState({});
  const [disabled, setDisabled] = useState(false);
  const _handleSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();

    const slackText = {
      text: `*Name:* ${contactInfo.name}\n*Email:* ${contactInfo.email}\n*Message:* ${contactInfo.message}`,
    };

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(slackText),
    };

    fetch('https://hooks.slack.com/services/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', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        toast.success('Form submitted successfully');
        setContactInfo({});
        setDisabled(false);
      })
      .catch((error) => {
        toast.error('Failed to submit. Please try again later');
        setDisabled(false);
      });

    setContactInfo({});
  };

  const _handleChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  return (
    <>
      <form className="form-contact" onSubmit={_handleSubmit}>
        <div className="form-element name">
          <label>
            Name<span>*</span>
          </label>
          <input required={true} type="text" name="name" value={contactInfo.name ?? ''} onChange={_handleChange} />
        </div>
        <div className="form-element email">
          <label>
            Email address<span>*</span>
          </label>
          <input required={true} type="email" name="email" value={contactInfo.email ?? ''} onChange={_handleChange} />
        </div>
        <div className="form-element message">
          <label>
            Message<span>*</span>
          </label>
          <textarea
            required={true}
            name="message"
            value={contactInfo.message ?? ''}
            onChange={_handleChange}
          ></textarea>
        </div>
        <div className="form-element submit">
          <input type="submit" value="Continue" className="btn btn-md btn-primary-ii btn-submit" disabled={disabled} />
        </div>
      </form>
      {/* {isSuccessfullySubmitted && <div className="success">Form submitted successfully</div>} */}
    </>
  );
}
