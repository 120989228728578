import React from 'react';
import { isEmpty } from 'lodash';
import iconFacebookAlt from '../../images/icon-facebook-alt.svg';
import iconPinterestAlt from '../../images/icon-pinterest-alt.svg';
import iconTwitterAlt from '../../images/icon-twitter-alt.svg';
import iconLinkedinAlt from '../../images/icon-linkedin-alt.svg';
import iconPrinter from '../../images/icon-printer.svg';

export default function SocialShare({ title, link, className }) {
  if (isEmpty(link)) {
    link = 'undefined' !== typeof window ? window.location.href : '';
  }

  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    link
  )}&title=${encodeURIComponent(title)}`;
  const twitterLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=${encodeURIComponent(
    title
  )}`;
  const pinterestLink = `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    link
  )}&description=${encodeURIComponent(title)}`;
  const facebookLink = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(link)}&title=${encodeURIComponent(
    title
  )}`;

  const handlePrint = () => {
    var content = document.getElementById('divcontents');
    var pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  return (
    <div className={`social-share ${className}`}>
      <ul>
        <li>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <img src={iconFacebookAlt} alt="Facebook" />
          </a>
        </li>
        <li>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={iconTwitterAlt} alt="Twitter" />
          </a>
        </li>
        <li>
          <a href={pinterestLink} target="_blank" rel="noopener noreferrer">
            <img src={iconPinterestAlt} alt="Pinterest" />
          </a>
        </li>
        <li>
          <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
            <img src={iconLinkedinAlt} alt="Linkedin" />
          </a>
        </li>
        <li onClick={() => handlePrint()} className="cursor-pointer">
          <img src={iconPrinter} alt="Print" />
        </li>
      </ul>
      <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
    </div>
  );
}
